import { useEffect, useState, useRef } from 'react';
import { useData } from './inventoryActions';
import { getTranslations } from '../Languages/i18n';
import { API_RequestInventoryEdit, API_SendApprovralDecision } from './APICalls';
import "./edit.css";
import SETTINGS from "../Config/settings";

const langData = getTranslations();

export const Edit = ({ accessToken, refreshToken }) => {

    const data = useData();

    const [Data, setData] = useState();
    const [Documents, setDocuments] = useState();
    const [Pics, setPics] = useState();
    const [Genders, setGenders] = useState();
    const [Subgenders, setSubgenders] = useState();
    const [formData, setFormData] = useState();
    const [cpFormData, setCpFormData] = useState();
    const [filteredGenders, setFilteredGenders] = useState();
    const [filteredSubGenders, setFilteredSubGenders] = useState();
    const [TypeObject, setTypeObject] = useState();
    const [filteredTypeObject, setfilteredTypeObject] = useState();

    const [DominantMaterial, setDominantMaterial] = useState();
    const [filteredDominantMaterial, setFilteredDominantMaterial] = useState();
    const refToSave = useRef({});
    const [isModified, setIsModified] = useState();
    const [tags, setTags] = useState([]);
    const [editTag, setEditTag] = useState('');
    const [currentTag, setCurrentTag] = useState('');

    const image_path = SETTINGS.URL_ADDRESS.server_url + SETTINGS.URL_ADDRESS.inventory_thumbnails;



    const compareFormModifications = (original, modified) => {
        const changes = {};
        for (const key in original) {
            // Aplicar trim a los valores antes de compararlos
            const originalValue = typeof original[key] === 'string' ? original[key].trim() : original[key];
            const modifiedValue = typeof modified[key] === 'string' ? modified[key].trim() : modified[key];

            if (originalValue !== modifiedValue) {
                changes[key] = {
                    oldValue: original[key],
                    newValue: modified[key]
                };
            }
        }
        return changes;
    }

    useEffect(() => {

        if (data !== undefined) {

            if (data.piece) {
                setData(data.piece);

                setDocuments(data.documents);
                setPics(data.pics);
                setGenders(data.genders);
                setSubgenders(data.subgenders);

                setTypeObject(data.type_object);
                setfilteredTypeObject(TypeObject);

                setDominantMaterial(data.dominant_material);
                setFilteredDominantMaterial(DominantMaterial);

                setFilteredGenders(Genders);
                setFilteredSubGenders(Subgenders);

                const temp = {
                    origin_number: data.piece?.origin_number || '',
                    inventory_number: data.piece?.inventory_number || '',
                    catalog_number: data.piece?.catalog_number || '',
                    description_origin: data.piece?.description_origin || '',
                    description_inventory: data.piece?.description_inventory || '',
                    gender_id: {
                        title: (data.piece?.gender_info && data.piece.gender_info.length > 0) ? data.piece.gender_info[0].title : 'N/D',
                        _id: data.piece?._id ? data.piece.gender_id : 'N/D',
                    },
                    subgender_id: (data.piece?.subgender_info && data.piece.subgender_info.length > 0) ? data.piece.subgender_info[0].title : '',
                    type_object_id: (data.piece?.type_object_info && data.piece.type_object_info.length > 0) ? data.piece.type_object_info[0].title : '',
                    dominant_material_id: (data.piece?.dominant_material_info && data.piece.dominant_material_info.length > 0) ? data.piece.dominant_material_info[0].title : '',
                    tags: data.piece?.tags || '',
                    appraisal: data.piece?.appraisal || '',
                    base_or_frame: data.piece?.base_or_frame || '',
                    height: data.piece?.height || '',
                    width: data.piece?.width || '',
                    depth: data.piece?.depth || '',
                    diameter: data.piece?.diameter || '',
                    height_with_base: data.piece?.height_with_base || '',
                    width_with_base: data.piece?.width_with_base || '',
                    depth_with_base: data.piece?.depth_with_base || '',
                    diameter_with_base: data.piece?.diameter_with_base || ''
                }

                setFormData(temp);
                setCpFormData(temp);
                setIsModified(false);
                const tagsArray = temp.tags.split(',').map(tag => tag.trim());
                setTags(tagsArray);

            } else {
                if (data.changes) {
                    setIsModified(true);
                    setData(data);
                }
            }

        }

    }, [data, setData, setFormData, setGenders, setSubgenders, setPics, Genders, Subgenders, isModified, DominantMaterial, TypeObject]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;

        if (id === "tags") {

            if (value.endsWith(',')) {
                // Agrega el tag sin la coma al final y limpia el currentTag
                setTags([...tags, currentTag.trim()]);
                setCurrentTag('');
            } else {
                setCurrentTag(value); // Actualiza el tag actual mientras se escribe
            }


        }
        else {
            setFormData((prevData) => ({
                ...prevData,
                [id]: value
            }));
        }

    };
    // Función para guardar los cambios (puedes ajustarla para conectarla a una API o manejar el guardado local)
    const handleSave = (e) => {
        e.preventDefault();

        const changes = compareFormModifications(cpFormData, formData);
        const _id = Data._id;

        if (changes) {
            API_RequestInventoryEdit({ accessToken, refreshToken, _id, changes })
                .then((data) => {
                    console.log("response data again", data);
                });
        }
    };

    // Filtra las opciones de gender en función de lo que escribe el usuario
    const handleGenderFilter = (e) => {

        const { value } = e.target;
        const filtered = Genders.filter((gender) =>
            gender.title.toLowerCase().includes(value.toLowerCase())
        );
        if (filtered.length > 0) {

            setFormData({
                ...formData, gender_id: {
                    title: filtered[0].title,
                    _id: filtered[0]._id
                }
            });
        }
        setFilteredGenders(filtered);
    };

    const handleTypeObjectFilter = (e) => {

        const { value } = e.target;
        const filtered = TypeObject.filter((type_object) =>
            type_object.title.toLowerCase().includes(value.toLowerCase())
        );
        if (filtered.length > 0) {

            setFormData({
                ...formData, type_object_id: {
                    title: filtered[0].title,
                    _id: filtered[0]._id
                }
            });
        }
        setfilteredTypeObject(filtered);
    };

    const handleDominantMaterialFilter = (e) => {

        const { value } = e.target;
        const filtered = DominantMaterial.filter((dominant_material) =>
            dominant_material.title.toLowerCase().includes(value.toLowerCase())
        );
        if (filtered.length > 0) {

            setFormData({
                ...formData, dominant_material_id: {
                    title: filtered[0].title,
                    _id: filtered[0]._id
                }
            });
        }
        setfilteredTypeObject(filtered);
    };

    const handleSubGenderFilter = (e) => {
        const { value } = e.target;

        // Filtra la lista de genders
        const filtered = Subgenders.filter((subgender) =>
            subgender.title.toLowerCase().includes(value.toLowerCase())
        );
        if (filtered.length > 0) {
            setFormData({
                ...formData, subgender: {
                    title: filtered[0].title,
                    _id: filtered[0]._id
                }
            });
        }

        setFilteredSubGenders(filtered);
    };



    const handleApprovalDecision = (isApproved) => {

        const ID = Data.piece_id
        var data;
        if (ID !== undefined) {
            API_SendApprovralDecision({ accessToken, refreshToken, ID, isApproved }).
                then((response) => {
                    if (response.ok) {
                        setIsModified(false)
                    }

                })
            console.log(data);

        }
    }

    const handleTagClick = (index) => {
        // Colocar el tag en el input para permitir su edición
        setCurrentTag(tags[index]);
        setEditTag(index)

        //setTags(tags.filter((_, i) => i !== index)); // Eliminar el tag del array para evitar duplicados
    };

    const handleTagDelete = (index) => {
        setTags(tags.filter((_, i) => i !== index)); // Eliminar el tag seleccionado
    };

    const handleTagKeyDown = (e) => {
        if (e.key === 'Enter') {

            if (editTag !== '') {

                const result = tags.map((tag, index) => {
                    var solve = [];
                    if (index !== editTag) {
                        solve[index] = tag;
                    } else {
                        solve[index] = e.target.value;
                    }
                    return solve
                });
                setTags(result);
                setEditTag('');
                setCurrentTag('');
            }
        }
    };

    const handlePicsFilter = () => {



    }



    return (
        <>
            {isModified ? (
                <>

                    <div className="container">

                        <table className="table table-bordered table-striped">
                            <thead className="table-dark">
                                <tr>
                                    <th>{langData.pieceInventoryEdit.field}</th>

                                    <th>{langData.pieceInventoryEdit.oldValue}</th>
                                    <th>{langData.pieceInventoryEdit.newValue}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(Data || {}).map(([key, value]) => {
                                    if (key === 'piece_id' || key === 'changes') {
                                        return null; // Omitir esta entrada
                                    }
                                    const name_descriptor = langData.pieceDetailDescriptors.inventory[key]
                                    const exceptions = ["gender_id", "subgender_id"]

                                    return (
                                        <>
                                            {exceptions.includes(key) ? (<tr key={key}>
                                                <td>{name_descriptor}</td>
                                                <td>{value?.oldValue?.title ? value.oldValue.title : "No disponible"}</td>
                                                <td>{value?.newValue?.title ? value.newValue.title : "No disponible"}</td>
                                            </tr>
                                            ) : (<tr key={key}>
                                                <td>{name_descriptor}</td>
                                                <td>{value?.oldValue ? value.oldValue : "No disponible"}</td>
                                                <td>{value?.newValue ? value.newValue : "No disponible"}</td>
                                            </tr>)}
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                        <button className='btn btn-primary btn-sm me-3' onClick={() => handleApprovalDecision(true)}>Aprobar cambios</button>
                        <button className='btn btn-secondary btn-sm' onClick={() => handleApprovalDecision(false)} >Descartar cambios</button>

                    </div>
                </>

            ) : (

                <div className="container">

                    <form onSubmit={handleSave}>

                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="origin_number" className="form-label">{langData.pieceDetailDescriptors.inventory.origin_number}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="origin_number"
                                    value={formData?.origin_number ? formData?.origin_number : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="inventory_number" className="form-label">{langData.pieceDetailDescriptors.inventory.inventory_number}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inventory_number"
                                    value={formData?.inventory_number ? formData?.inventory_number : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="catalog_number" className="form-label">{langData.pieceDetailDescriptors.inventory.catalog_number}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="catalog_number"
                                    value={formData?.catalog_number ? formData?.catalog_number : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="row mb-3">

                            <div className="col">
                                <label htmlFor="description_origin" className="form-label">{langData.pieceDetailDescriptors.inventory.description_origin}</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="description_origin"
                                    value={formData?.description_origin ? formData?.description_origin : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="description_inventory" className="form-label">{langData.pieceDetailDescriptors.inventory.description_inventory}</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="description_inventory"
                                    value={formData?.description_inventory ? formData?.description_inventory : ''}
                                    onChange={handleInputChange}
                                    ref={(el) => (refToSave.current["description_inventory"] = el)}
                                />
                            </div>
                        </div>

                        <div className="row mb-3">

                            <div className="mb-3">
                                <label htmlFor="gender" className="form-label">{langData.pieceDetailDescriptors.inventory.gender}</label>

                                <input
                                    type="text"
                                    className="form-control"
                                    id="gender"
                                    onChange={handleGenderFilter}
                                    placeholder="Type to filter genders"
                                />

                                <select
                                    className="form-select mt-2"
                                    id="gender"
                                    value={formData?.gender_id?.title ? formData.gender_id.title : ''}

                                    onChange={handleGenderFilter}
                                >

                                    {filteredGenders?.map((gender, index) => {

                                        return (
                                            <option key={index} value={gender?.title ? gender.title : ''}>
                                                {gender?.title ? gender.title : ''}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className="mb-3">

                                <label htmlFor="subgender" className="form-label">{langData.pieceDetailDescriptors.inventory.subgenders_info}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="subgender"

                                    onChange={handleSubGenderFilter}
                                    placeholder="Type to filter sub-genders"
                                />

                                <select
                                    type="text"
                                    className="form-select mt-2"
                                    id="subgender"
                                    value={formData?.subgender_id ? formData.subgender_id : ''}
                                    onChange={handleSubGenderFilter}
                                >
                                    {filteredSubGenders?.map((subgender, index) => {

                                        return (
                                            <option key={index} value={subgender?.title ? subgender.title : ''}>
                                                {subgender?.title ? subgender.title : ''}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className="col">
                                <label htmlFor="type_object" className="form-label">{langData.pieceDetailDescriptors.inventory.type_object_info}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="type_object"

                                    onChange={handleTypeObjectFilter}
                                />
                                <select
                                    type="text"
                                    className="form-select mt-2"
                                    id="type_object"
                                    value={formData?.type_object_id ? formData.type_object_id : ''}
                                    onChange={handleTypeObjectFilter}
                                >
                                    {filteredTypeObject?.map((type_object, index) => {

                                        return (
                                            <option key={index} value={type_object?.title ? type_object.title : ''}>
                                                {type_object?.title ? type_object.title : ''}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-3">

                            <div className="col">
                                <label htmlFor="dominant_material" className="form-label">{langData.pieceDetailDescriptors.inventory.dominant_material_info}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="dominant_material"

                                    onChange={handleDominantMaterialFilter}
                                />
                                <select
                                    type="text"
                                    className="form-select mt-2"
                                    id="type_object"
                                    value={formData?.dominant_material_id ? formData.dominant_material_id : ''}
                                    onChange={handleDominantMaterialFilter}
                                >
                                    {filteredDominantMaterial?.map((dominant_material, index) => {

                                        return (
                                            <option key={index} value={dominant_material?.title ? dominant_material.title : ''}>
                                                {dominant_material?.title ? dominant_material.title : ''}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>



                            <div className="col">
                                <label htmlFor="tags" className="form-label">{langData.pieceDetailDescriptors.inventory.tags}</label>
                                <div className="tags-input">
                                    {tags.map((tag, index) => (
                                        <span key={index} className="tag" onClick={() => handleTagClick(index)}>
                                            {tag}
                                            <button type="button" onClick={(e) => { e.stopPropagation(); handleTagDelete(index); }} className="delete-tag">x</button>
                                        </span>
                                    ))}
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="tags"
                                        value={currentTag}
                                        onChange={handleInputChange}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault(); // Evita que Enter dispare el submit del formulario
                                                handleTagKeyDown(e); // Si aún quieres procesar Enter para agregar un tag, llama a tu función aquí
                                            }
                                        }}

                                    />
                                </div>

                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="appraisal" className="form-label">{langData.pieceDetailDescriptors.inventory.appraisal}</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="appraisal"
                                    value={formData?.appraisal ? formData?.appraisal : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>


                        <h5>Dimensions (without base)</h5>
                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="height" className="form-label">{langData.pieceDetailDescriptors.inventory.height}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="height"
                                    value={formData?.height ? formData?.height : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="width" className="form-label">{langData.pieceDetailDescriptors.inventory.width}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="width"
                                    value={formData?.width ? formData?.width : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="depth" className="form-label">{langData.pieceDetailDescriptors.inventory.depth}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="depth"
                                    value={formData?.depth ? formData?.depth : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="diameter" className="form-label">{langData.pieceDetailDescriptors.inventory.diameter}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="diameter"
                                    value={formData?.diameter ? formData?.diameter : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>


                        <h5>Dimensions (with base)</h5>
                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="height_with_base" className="form-label">{langData.pieceDetailDescriptors.inventory.height_with_base}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="height_with_base"
                                    value={formData?.height_with_base ? formData?.height_with_base : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="width_with_base" className="form-label">{langData.pieceDetailDescriptors.inventory.width_with_base}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="width_with_base"
                                    value={formData?.width_with_base ? formData?.width_with_base : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="depth_with_base" className="form-label">{langData.pieceDetailDescriptors.inventory.depth_with_base}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="depth_with_base"
                                    value={formData?.depth_with_base ? formData?.depth_with_base : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="diameter_with_base" className="form-label">{langData.pieceDetailDescriptors.inventory.diameter_with_base}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="diameter_with_base"
                                    value={formData?.diameter_with_base ? formData?.diameter_with_base : ''}
                                    onChange={handleInputChange}
                                />
                            </div>


                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <div className="card mt-4">
                                        <div className="card-header bg-info text-center">
                                            Imágenes
                                        </div>
                                        <div className="card-body text-center">
                                            {Pics?.map((pic, index) => (
                                                <div key={index} className="mb-4">
                                                    <img
                                                        alt="thumbnail"
                                                        src={`${image_path}${pic.file_name}`}
                                                        className="img-fluid mb-3 rounded"
                                                        style={{ maxHeight: "200px", maxWidth: "100%", objectFit: "cover" }}
                                                    />
                                                    <div className="text-start">
                                                        <label className="d-block">
                                                            Fotógrafo:
                                                            <input
                                                                id="photographer"
                                                                type="text"
                                                                value={pic.photographer}
                                                                className="form-control mt-2"
                                                            />
                                                        </label>
                                                        <label className="d-block mt-3">
                                                            Fecha de fotografiado:
                                                            <input
                                                                id="photographed_at"
                                                                type="date"
                                                                value={formatDate(pic.photographed_at)}
                                                                className="form-control mt-2"
                                                            />
                                                        </label>
                                                        <label className="d-block mt-3">
                                                            Descripción:
                                                            <textarea
                                                                id="description"
                                                                type="text"
                                                                value={pic.description}
                                                                className="form-control mt-2"
                                                            />
                                                        </label>
                                                        <label className="d-block mt-3">
                                                            Tamaño:
                                                            <p className="mt-2">{formatSize(pic.size)}</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='row'>
                                <div className="col">
                                    <div className="card mt-4 ">
                                        <div class="card-header bg-info">
                                            Documentos
                                        </div>
                                        <div class="card-body">
                                            {Documents?.map((doc, index) => {
                                                return (
                                                    <>
                                                        <span key={index}>{doc.file_name}</span>
                                                        <br />
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <button type="submit" className="btn btn-primary">Save</button>
                    </form>
                </div>)}

        </>

    );

}


// Agrega esta función en el componente para formatear la fecha:
function formatDate(dateTime) {
    return dateTime.split(" ")[0]; // Extrae solo la fecha en formato YYYY-MM-DD
}

function formatSize(bytes) {
    const units = ["Bytes", "KB", "MB", "GB", "TB"];
    let i = 0;
    let size = bytes;
    while (size >= 1024 && i < units.length - 1) {
        size /= 1024;
        i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
}
